var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-wrapper',{attrs:{"text-color":_vm.textColor,"color":_vm.color,"class-for-view-round":_vm.classForViewRound,"change-mode":_vm.changeMode}},[_c('div',{staticClass:"card__header--loading-space",style:({ background: _vm.draftConfiguration.wb_color_primary__color__ })}),_c('div',{staticClass:"d-flex align-center py-4"},[_c('div',{staticClass:"full-width"},[(!_vm.showAnyEmployees && _vm.isMobile)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-avatar',{staticClass:"mb-4",attrs:{"left":"","size":"80"}},[_c('v-img',{attrs:{"src":_vm.placeholderImageUrl}})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[(_vm.showAnyEmployees)?[_c('div',{staticClass:"d-flex align-center ml-2"},[_c('v-avatar',{class:{
                        'overlapping-avatar': true,
                      },attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.placeholderImageUrl}})],1),_c('v-avatar',{class:{
                        'overlapping-avatar': true,
                        'ml-n5': true
                      },attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.placeholderImageUrl}})],1),_c('v-avatar',{class:{
                        'overlapping-avatar': true,
                        'ml-n5': true
                      },attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.placeholderImageUrl}})],1)],1)]:[(!_vm.isMobile)?_c('v-avatar',{staticClass:"ml-2",attrs:{"left":"","size":"80"}},[_c('v-img',{attrs:{"src":_vm.placeholderImageUrl}})],1):_vm._e()],(!_vm.showAnyEmployees)?_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-h6 text-wrap text-break"},[_vm._v(_vm._s(_vm.$trans('employee_example_fullname')))]),_c('div',[_vm._v(_vm._s(_vm.$trans('role_manager')))]),(_vm.socialMedia.length > 0)?_c('div',{staticClass:"ml-n2"},[_vm._l((_vm.socialMedia),function(item){return [(item.link && item.link.length > 0)?_c('v-btn',{key:item.icon,attrs:{"href":item.link,"color":"wb_color_primary__color__","icon":"","target":"_blank"}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1):_vm._e()]})],2):_vm._e()]):_vm._e(),(_vm.showAnyEmployees)?_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-h6 text-wrap text-break"},[_vm._v(_vm._s(_vm.$trans('any_person_label')))])]):_vm._e()],2),(!_vm.showAnyEmployees)?_c('div',{staticClass:"text-break px-4 pt-4"},[_vm._v(" "+_vm._s(_vm.$trans('wb_dummy_text_employee_description'))+" ")]):_vm._e()]),(_vm.changeMode && !_vm.isMobile)?_c('div',{staticClass:"px-4"},[_c('v-btn',{attrs:{"color":"wb_color_primary__color__","text":""}},[_vm._v(_vm._s(_vm.$trans('change')))])],1):_vm._e()]),(_vm.changeMode && _vm.isMobile)?_c('div',{staticClass:"d-flex align-center justify-center py-4"},[_c('v-btn',{attrs:{"color":"wb_color_primary__color__","text":""}},[_vm._v(_vm._s(_vm.$trans('change')))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }