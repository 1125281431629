






import Vue from 'vue'
import moment from 'moment/moment'

export default Vue.extend({
  name: 'TimeZoneText',
  data () {
    return {
      customerTimeZone: moment.tz.guess(),
      customerCurrentTime: moment().format(this.$helpers.timeFormat)
    }
  }
})
