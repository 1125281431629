
























































































































































































import mixins from 'vue-typed-mixins'
import CCalendarPanels from '@/builder/sections/section/calendars/components/CCalendarPanels.vue'
import TimeZoneText from '@/builder/sections/shared/TimeZoneText.vue'
import CalendarV2Actions from '@/builder/sections/section/shared/mixins/CalendarV2Actions'

export default mixins(CalendarV2Actions).extend({
  name: 'CalendarV2',
  components: {
    TimeZoneText,
    CCalendarPanels
  }
})
