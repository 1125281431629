





























































import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import CCalendarLocationPanel from '@/builder/sections/section/calendars/components/CCalendarLocationPanel.vue'
import CCalendarServicePanel from '@/builder/sections/section/calendars/components/CCalendarServicePanel.vue'
import CCalendarEmployeePanel from '@/builder/sections/section/calendars/components/CCalendarEmployeePanel.vue'

export default mixins(DraftElement).extend({
  name: 'CCalendarPanels',
  components: {
    CCalendarEmployeePanel,
    CCalendarServicePanel,
    CCalendarLocationPanel
  },
  props: {
    selectLocationsTitle: {
      type: String,
      default: ''
    },
    selectServicesTitle: {
      type: String,
      default: ''
    },
    selectEmployeesTitle: {
      type: String,
      default: ''
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    isSettingRandomEmployeeSupported: {
      type: Boolean,
      default: false
    }
  }
})
