export function getRgbaColor(hex, alpha) {
  let r = 0;
  let g = 0;
  let b = 0;
  hex = hex.replace("#", "");

  if (hex.length === 3 || hex.length === 4) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
    // When 4, the fourth character is ignored as it's assumed to be alpha in some contexts
  } else if (hex.length === 6 || hex.length === 8) {
    r = parseInt(hex[0] + hex[1], 16);
    g = parseInt(hex[2] + hex[3], 16);
    b = parseInt(hex[4] + hex[5], 16);
    // When 8, the last two characters are ignored as they are assumed to be alpha
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
