var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section d-flex align-center",class:( _obj = { 'section-invert': _vm.invertColors}, _obj[_vm.textColorVar + '--text'] = true, _obj ),style:({ 'min-height': ((_vm.data.configuration['wb_height__style_height__']) + "px") })},[_c('v-container',{staticClass:"calendar-v2"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('c-calendar-panels',{attrs:{"background-color":_vm.backgroundColor1,"class-for-view-round":_vm.classForViewRound,"is-setting-random-employee-supported":_vm.data.configuration.wb_any_employee_enabled__checkbox__,"text-color":_vm.textColorVar + '--text',"select-locations-title":_vm.data.configuration.wb_select_locations_title_label__html_text__,"select-services-title":_vm.data.configuration.wb_select_services_title_label__html_text__,"select-employees-title":_vm.data.configuration.wb_select_employees_title_label__html_text__}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"d-flex align-center",class:{
                      'justify-start': !_vm.isMobile,
                      'justify-center': _vm.isMobile
                    }},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"wb_color_primary__color__"}},[_vm._v("$calendar")]),_c('span',{staticClass:"text-h5 font-weight-medium"},[_vm._v(_vm._s(_vm.getCalendarRangeInfo))])],1),(!_vm.isTimeZoneHidden)?_c('div',{staticClass:"d-flex align-center mt-2",class:{
                      'justify-start ml-n1': !_vm.isMobile,
                      'justify-center': _vm.isMobile
                    }},[_c('time-zone-text')],1):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center",class:{
                'justify-end': !_vm.isMobile,
                'justify-center': _vm.isMobile,
              },attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-btn',{attrs:{"small":_vm.isMobile,"color":"wb_color_primary__color__","fab":"","text":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("$chevron_left")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"small":_vm.isMobile,"color":"wb_color_primary__color__","outlined":""}},[_vm._v(" "+_vm._s(_vm.$trans('today'))+" ")]),_c('v-btn',{attrs:{"small":_vm.isMobile,"color":"wb_color_primary__color__","fab":"","text":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("$chevron_right")])],1)],1)],1),_c('v-row',[_vm._l((_vm.getAllRangeDates),function(date,dayIndex){return _c('v-col',{key:date.date,staticClass:"d-flex align-start justify-center",class:{
                'weekday-col': !_vm.isMobile,
                'weekday-col-mobile': _vm.isMobile,
              }},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(date.dayShort))+", "),_c('br'),_vm._v(" "+_vm._s(date.dayNumber)+" "+_vm._s(_vm._f("capitalize")(date.monthShort))+" ")]),_c('div',[_c('div',{class:{
                      'time-slots-wrapper': _vm.showExpandCollapseButton,
                      'time-slots-wrapper-dark': _vm.isDarkMode,
                      'expanded': !_vm.showExpandCollapseButton
                    },style:(_vm.gradientStyle)},_vm._l((_vm.getRandomSlots[dayIndex].slots),function(time,index){return _c('div',{key:index + "" + _vm.showBookedSlots,staticClass:"text-center mb-2"},[(!_vm.showExpandCollapseButton || index < 6 || !_vm.showExpandCollapseButton)?[(typeof time === 'object' && time.time)?[(!time.booked)?[_c('v-btn',{staticClass:"multi-line-button",class:{
                                'white--text': _vm.isTimeSlotSelected(date.instance, time)
                              },attrs:{"color":"wb_color_primary__color__","outlined":!_vm.isTimeSlotSelected(date.instance, time)}},[_c('div',{staticClass:"slot-button-content"},[_vm._v(" "+_vm._s(time.time)+" ")])])]:_c('div',{staticClass:"text-decoration-line-through empty-slot d-flex align-center justify-center"},[_vm._v(" "+_vm._s(time.time)+" ")])]:[_c('div',{staticClass:"empty-slot d-flex align-center justify-center"},[_vm._v("-")])]]:_vm._e()],2)}),0)])])])}),(_vm.showExpandCollapseButton)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"wb_color_primary__color__","small":"","text":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v("$menu_down")]),_vm._v(" "+_vm._s(_vm.$trans('show_more_hours_button'))+" ")],1)],1):_vm._e()],2)],1)],1)],1),(_vm.canUseCart && _vm.bookingCartSlots.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$trans('selected_time_slots_label'))+": ")]),_vm._l((_vm.bookingCartSlots),function(cartSlot){return [_c('div',{key:cartSlot.key,staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":_vm.isDarkMode}},[_vm._v(" $info ")]),_c('div',[_vm._v(_vm._s(cartSlot.human_date_time))]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","dark":_vm.isDarkMode}},[_c('v-icon',[_vm._v(" $trash-default ")])],1)],1)]})],2),_c('v-col',{staticClass:"d-flex align-center",class:{
          'justify-end': !_vm.isMobile,
          'justify-center': _vm.isMobile,
        },attrs:{"cols":_vm.isMobile ? 12: 6}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","outlined":""}},[_vm._v(" "+_vm._s(_vm.$trans('add_another_button_title'))+" + ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":_vm.bookingCartSlots.length === 0,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$trans('wb_dummy_text_book_now'))+" ("+_vm._s(_vm.bookingCartSlots.length)+") ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }