













































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import VCardWrapper from '@/builder/sections/section/calendars/components/VCardWrapper.vue'

export default mixins(DraftElement).extend({
  components: { VCardWrapper },
  props: {
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    showAnyEmployees: {
      type: Boolean,
      default: null
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    changeMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    socialMedia (): Record<string, any>[] {
      // Create an array with all possible social media entries
      const allEntries = [
        {
          icon: '$fb',
          link: '#'
        },
        {
          icon: '$instagram',
          link: '#'
        },
        {
          icon: '$yt',
          link: '#'
        }
      ]

      // Filter the array to include only those entries where the link exists
      return allEntries.filter(entry => entry.link)
    }
  }
})
