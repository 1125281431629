












import adjustColor from '@/lib/calendesk-js-library/filters/adjustColor'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import mixins from 'vue-typed-mixins'

export default mixins(DraftElement).extend({
  props: {
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    changeMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColor (): string {
      if (this.changeMode) {
        return adjustColor(this.color, this.isDarkMode ? 0.05 : -0.05)
      }
      return this.color
    }
  }
})
