import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import moment, { Moment } from 'moment/moment'
import { getRgbaColor } from '@/lib/calendesk-js-library/filters/getRgbaColor'
import getHumanDateTime from '@/lib/calendesk-js-library/filters/humanDateTime'
export default mixins(Section).extend({
  data () {
    return {

    }
  },
  computed: {
    bookingCartSlots () {
      return [
        {
          name: this.$trans('wb_dummy_text_service'),
          key: 'first',
          date_start: moment().format(this.$helpers.dateFormat),
          human_date_time: getHumanDateTime(
            moment().hour(8).minute(0), moment().hour(9).minute(0), this.hideBookingEndTime)
        },
        {
          name: this.$trans('wb_dummy_text_service'),
          key: 'second',
          human_date_time: getHumanDateTime(
            moment().hour(9).minute(0), moment().hour(9).minute(0), this.hideBookingEndTime)
        }
      ]
    },
    showExpandCollapseButton (): boolean {
      return this.data.configuration.wb_show_expand_collapse_button__checkbox__
    },
    showBookedSlots (): boolean {
      return this.data.configuration.wb_show_booked_slots__checkbox__
    },
    canUseCart (): boolean {
      return this.data.configuration.wb_use_booking_cart__checkbox__
    },
    startDate (): Moment {
      if (this.isMobile) {
        return this.$moment()
      }

      return this.$moment().startOf('week')
    },
    endDate (): Moment {
      if (this.isMobile) {
        return this.$moment().add(4, 'days')
      }

      return this.$moment().endOf('week')
    },
    getAllRangeDates (): Array<Record<string, any>> {
      const result = []
      if (this.startDate) {
        for (const n in [...Array(this.isMobile ? 5 : 7).keys()]) {
          const dateClone = this.startDate.clone().add(n, 'days') as Moment
          result.push({
            instance: dateClone,
            date: dateClone.format(this.$helpers.dateFormat),
            dayShort: dateClone.format('ddd'),
            dayNumber: dateClone.format('D'),
            monthShort: dateClone.format('MMM')
          })
        }
      }

      return result
    },
    getCalendarRangeInfo (): string {
      const startDate = this.startDate.format('Do MMM')
      const endDate = this.endDate.format('Do MMM YYYY')
      return startDate + ' - ' + endDate
    },
    gradientStyle () {
      const baseColorHex = this.draftConfiguration.wb_color_bg__color__
      const rgbaColor = getRgbaColor(baseColorHex, 0)
      return {
        '--dynamic-gradient': `linear-gradient(to bottom, ${rgbaColor}, ${baseColorHex})`
      }
    },
    getRandomSlots (): object[] {
      const slotData = [
        {
          day: 0,
          slots: this.generateSlots(8, 21, [11, 12])
        },
        {
          day: 1,
          slots: this.generateSlots(8, 21, [13, 14])
        },
        {
          day: 2,
          slots: this.generateSlots(11, 17, [])
        },
        {
          day: 3,
          slots: this.generateSlots(8, 17, [13, 14, 15])
        },
        {
          day: 4,
          slots: this.generateSlots(8, 17, [8, 9, 10])
        },
        {
          day: 5,
          slots: this.generateSlots(8, 17, [9, 10, 11, 12])
        },
        {
          day: 6,
          slots: this.generateSlots(10, 14, [13, 14])
        }
      ]

      const maxSlots = Math.max(...slotData.map(dayData => dayData.slots.length))
      return this.showBookedSlots
        ? slotData.map(dayData => ({
          day: dayData.day,
          slots: dayData.slots.concat(Array(maxSlots - dayData.slots.length)
            .fill('-'))
            .map((slot, index) => ({ ...slot, key: index }))
        }))
        : slotData.map(dayData => ({
          day: dayData.day,
          slots: dayData.slots.filter(slot => !slot.booked)
            .concat(Array(maxSlots - dayData.slots.length)
              .fill('-'))
            .map((slot, index) => ({ ...slot, key: index }))
        }))
    }
  },
  methods: {
    isTimeSlotSelected (date: Moment, timeSlot: any): boolean {
      const isToday = date.isSame(this.$moment(), 'day')
      return isToday && (timeSlot.key === 0 || timeSlot.key === 1)
    },
    generateSlots (startHour: number, endHour: number, booked: number[]) {
      const slots = []
      for (let hour = startHour; hour <= endHour; hour++) {
        const formattedHour = hour < 10 ? '0' + hour : hour
        slots.push({
          time: formattedHour + ':00',
          booked: booked.includes(Number(formattedHour))
        })
      }
      return slots
    }
  }
})
